<template>
  <section v-if="!canShowSection()" id="promo-box-section" class="section">
    <div id="infos-1" class="grid-container">
      <article class="info-box">
        <h3 class="article-title">Oferta dostępna wyłącznie dla Klientów korzystających z Shoper Płatności</h3>
        <p class="article-text">Dowiedz się jak uruchomić Shoper Płatności w swoim sklepie</p>
        <div class="button-container">
          <a class="shoper-button-outline" href="https://www.shoper.pl/integracje/systemy-platnosci/" title="Przejdź do strony Shoper Płatności">Włącz Shoper Płatności</a>
        </div>
      </article>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PromoBoxSection',
  data () {
    return {
      isHash: this.$route.params.hash
    }
  },
  methods: {
    canShowSection () {
      return this.isHash && !this.isHashError && !this.isLoading
    }
  },
  computed: {
    ...mapState({
      isHashError: state => state.agreements.isHashError,
      isLoading: state => state.agreements.isLoading
    })
  }
}
</script>

<style lang="scss" src="../../../assets/scss/partners/shoper/sections/_promobox.scss"></style>
