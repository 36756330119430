<template>
  <main class="main">
    <Header />
    <hero-section />
    <promo-box-section />
    <infos-profit-section />
    <partner-info />
    <infos-specificity-section />
    <infos-process-section />
    <calculator-section :content="calculator" />
    <simulation-section :content="simulation" />
    <scenario-section :content="scenarios">
      <button-open-dialog />
    </scenario-section>
    <questions-section />
    <agreements-dialog v-if="isVisible" />
    <button-scroll-up />
  </main>
</template>

<script>
import Header from '../shoper/components/Header'
import HeroSection from '../shoper/components/HeroSection'
import InfosProfitSection from '../shoper/components/InfosProfitSection'
import PartnerInfo from '../shoper/components/PartnerInfo'
import InfosSpecificitySection from '../shoper/components/InfosSpecificitySection'
import InfosProcessSection from '../shoper/components/InfosProcessSection'
import QuestionsSection from '../shoper/components/QuestionsSection'
import PromoBoxSection from '../shoper/components/PromoBoxSection'

import AgreementsDialog from '../../components/agreements/AgreementsDialog'
import ButtonScrollUp from '../../components/common/ButtonScrollUp'
import CalculatorSection from '../../components/calculator/CalculatorSection'
import SimulationSection from '../../components/simulation/SimulationSection'
import ScenarioSection from '../../components/scenarios/ScenarioSection'
import ButtonOpenDialog from '../../components/common/ButtonOpenDialog'

import ContentMixin from '../../mixins/content.mixin'

export default {
  name: 'ShoperLanding',
  components: {
    PromoBoxSection,
    Header,
    HeroSection,
    InfosProfitSection,
    PartnerInfo,
    InfosSpecificitySection,
    QuestionsSection,
    InfosProcessSection,
    CalculatorSection,
    SimulationSection,
    ScenarioSection,
    ButtonOpenDialog,
    AgreementsDialog,
    ButtonScrollUp
  },
  mixins: [ContentMixin]
}

</script>

<style lang="scss" src="../../assets/scss/partners/shoper/_global.scss"></style>
